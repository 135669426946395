// Testimonial Section

.testimonial-item{
	padding:50px 30px;

	p{
		font-size: 18px;
		line-height: 32px;
	}

}

.slick-slide {
  &:focus, a {
    outline: none;
  }
}

.shadow-md-2{
	box-shadow: 0 18px 50px rgba(0, 0, 0, 0.07);
}

