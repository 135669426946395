/*=== MEDIA QUERY ===*/
@import url(http://fonts.googleapis.com/css?family=Lato:300,400,700|Montserrat;:300,400,600,700,800|Source+Sans+Pro:300,400,600,700);
html {
  overflow-x: hidden;
}

body {
  font-family: "Source Sans Pro", sans-serif;
  -webkit-font-smoothing: antialiased;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.166667px;
  line-height: 1.7em;
  color: #7e8690;
}

p {
  color: #7e8690;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  font-family: "Montserrat", sans-serif;
  color: #111;
  font-weight: 800;
  text-transform: capitalize;
}

h1, .h1 {
  font-size: 45px;
  text-transform: none;
}

h2, .h2 {
  font-size: 42px;
  line-height: 52px;
}

h3, .h3 {
  font-size: 20px;
  line-height: 32px;
}

h4, .h4 {
  font-size: 18px;
  line-height: 30px;
}

h5, .h5 {
  font-size: 16px;
  line-height: 26px;
}

h6, .h6 {
  font-size: 14px;
}

.text-sm {
  font-size: 14px;
}

.navbar-toggle .icon-bar {
  background: #4869e8;
}

input[type="email"], input[type="password"], input[type="text"], input[type="tel"] {
  box-shadow: none;
  height: 45px;
  outline: none;
  font-size: 14px;
}

input[type="email"]:focus, input[type="password"]:focus, input[type="text"]:focus, input[type="tel"]:focus {
  box-shadow: none;
  border: 1px solid #4869e8;
}

.form-control {
  box-shadow: none;
  border-radius: 0;
}

.form-control:focus {
  box-shadow: none;
  border: 1px solid #4869e8;
}

.py-7 {
  padding: 7rem 0px;
}

.btn {
  display: inline-block;
  padding: 1rem 2rem;
  text-transform: uppercase;
  border-radius: 4px;
  font-size: 14px;
  line-height: 14px;
  font-weight: 600;
  box-shadow: #999999 0px 0px 0px 0px;
  border: 1px solid transparent;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.btn.btn-icon i {
  font-size: 16px;
  vertical-align: middle;
  margin-right: 5px;
}

.btn:focus {
  outline: 0px;
  border: 0px;
  box-shadow: none;
}

.btn-main, .btn-transparent, .btn-small {
  background: #4869e8;
  color: #fff;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.btn-main:hover, .btn-transparent:hover, .btn-small:hover {
  border-color: #111;
  background: #111;
  color: #fff;
}

.btn-white {
  background: #fff;
  color: #111;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.btn-white:hover {
  border-color: #4869e8;
  background: #4869e8;
  color: #fff;
}

.btn-solid-border {
  border-color: #dedede;
  background: transparent;
  color: #fff;
}

.btn-solid-border:hover {
  border-color: #4869e8;
  background: #4869e8;
  color: #fff;
}

.btn-main-border {
  border-color: #4869e8;
  background: transparent;
  color: #fff;
}

.btn-main-border:hover {
  border-color: #4869e8;
  background: #4869e8;
  color: #fff;
}

.btn-transparent {
  background: transparent;
  padding: 0;
  color: #4869e8;
}

.btn-transparent:hover {
  background: transparent;
  color: #4869e8;
}

.btn-large {
  padding: 20px 45px;
}

.btn-large.btn-icon i {
  font-size: 16px;
  vertical-align: middle;
  margin-right: 5px;
}

.btn-small {
  padding: 10px 25px;
  font-size: 12px;
}

.btn-round {
  border-radius: 4px;
}

.btn-round-full {
  border-radius: 50px;
}

.btn.active:focus, .btn:active:focus, .btn:focus {
  outline: 0;
}

.section {
  padding: 100px 0;
}

.section-title {
  margin-bottom: 70px;
}

.section-title h2 {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 53px;
  line-height: 63px;
}

.overly {
  position: relative;
}

.overly:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  background: #000;
}

.overly-2, .page-title, .slider {
  position: relative;
}

.overly-2:before, .page-title:before, .slider:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(23, 24, 33, 0.75);
}

.overly-3, .bg-counter, .hero-img {
  position: relative;
}

.overly-3:before, .bg-counter:before, .hero-img:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: #4869e8;
  opacity: .95;
}

.text-color {
  color: #4869e8;
}

.text-black {
  color: #111;
}

.text-color2 {
  color: #f94877;
}

.text-sm {
  font-size: 14px;
}

.text-md {
  font-size: 32px;
  line-height: 42px;
}

.text-lg {
  font-size: 80px;
}

.no-spacing {
  letter-spacing: 0px;
}

/* Links */
a {
  color: #111;
  text-decoration: none;
}

a:focus, a:hover {
  color: #4869e8;
  text-decoration: none;
}

a:focus {
  outline: none;
}

.content-title {
  font-size: 40px;
  line-height: 50px;
}

.page-title {
  padding-top: 220px;
  padding-bottom: 120px;
}

.page-title h1 {
  color: #fff;
}

.page-title p {
  color: #fff;
}

.bg-gray {
  background: #f5f5f5;
}

.bg-black {
  background: #111;
}

.bg-gradient {
  background-image: linear-gradient(145deg, rgba(19, 177, 205, 0.95) 0%, rgba(152, 119, 234, 0.95) 100%);
  background-repeat: repeat-x;
}

.bg-primary {
  background: #4869e8 !important;
}

#wrapper-work {
  overflow: hidden;
  padding-top: 100px;
}

#wrapper-work ul li {
  width: 50%;
  float: left;
  position: relative;
}

#wrapper-work ul li img {
  width: 100%;
  height: 100%;
}

#wrapper-work ul li .items-text {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  color: #fff;
  background: rgba(0, 0, 0, 0.6);
  padding-left: 44px;
  padding-top: 140px;
}

#wrapper-work ul li .items-text h2 {
  padding-bottom: 28px;
  padding-top: 75px;
  position: relative;
}

#wrapper-work ul li .items-text h2:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 75px;
  height: 3px;
  background: #fff;
}

#wrapper-work ul li .items-text p {
  padding-top: 30px;
  font-size: 16px;
  line-height: 27px;
  font-weight: 300;
  padding-right: 80px;
}

/*--
	features-work Start 
--*/
#features-work {
  padding-top: 50px;
  padding-bottom: 75px;
}

#features-work .block ul li {
  width: 19%;
  text-align: center;
  display: inline-block;
  padding: 40px 0px;
}

.navigation {
  position: absolute;
  width: 100%;
  z-index: 9999;
}

#navbar li {
  padding-left: 15px;
}

#navbar .nav-link {
  font-family: "Source Sans Pro", sans-serif;
  text-transform: capitalize;
  color: #f5f5f5;
  font-weight: 600;
  letter-spacing: 0.3px;
  font-size: 18px;
  line-height: 26px;
  -webkit-transition: all .25s ease;
  -moz-transition: all .25s ease;
  -ms-transition: all .25s ease;
  -o-transition: all .25s ease;
  transition: all .25s ease;
}

#navbar .nav-link:hover, #navbar .nav-link:focus {
  color: #4869e8;
}

#navbar .btn {
  padding: .5rem 1.5rem;
}

.header-top {
  padding: 10px 0px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
}

.header-top .header-top-socials {
  border-left: 1px solid rgba(255, 255, 255, 0.05);
}

.header-top .header-top-socials a {
  margin-right: 15px;
  color: rgba(255, 255, 255, 0.85);
  font-size: 14px;
}

.header-top .header-top-info a {
  color: rgba(255, 255, 255, 0.85);
  padding-right: 35px;
  font-size: 14px;
}

.header-top .header-top-info a:first-child {
  padding-left: 0px;
}

.text-black {
  color: #111;
}

.quote-btn {
  display: inline-block;
  padding: 6px 15px;
  font-size: 13px;
}

.rounded-btn {
  border-radius: 4px;
}

#navbar .navbar-brand {
  color: #fff;
  font-weight: 700;
}

.dropdown {
  position: relative;
}

.dropdown-menu {
  visibility: hidden;
  filter: alpha(opacity=0);
  opacity: 0;
  width: 250px;
  margin-top: 15px;
  padding: 0px;
  border-radius: 0px;
  border-top: 3px solid #4869e8;
  background: #111;
  box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05), -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
  webkit-transform: translateY(20px);
  -ms-transform: translateY(20px);
  transform: translateY(20px);
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  position: absolute !important;
  left: 0px;
  top: 100%;
  width: 240px;
  z-index: 100;
  display: block !important;
  margin: 0 auto;
}

.dropdown-toggle::after {
  display: none;
}

.dropdown:hover .dropdown-menu {
  visibility: visible;
  opacity: 1;
  webkit-transform: translateY(0px);
  -ms-transform: translateY(0px);
  transform: translateY(0px);
}

.dropdown-item {
  padding: 9px 23px;
  text-transform: uppercase;
  font-size: 14px;
  color: #fff !important;
  -webkit-transition: all .45s ease 0s;
  -moz-transition: all .45s ease 0s;
  -ms-transition: all .45s ease 0s;
  -o-transition: all .45s ease 0s;
  transition: all .45s ease 0s;
}

.dropdown-item:hover {
  background: #4869e8;
  color: #fff;
}

ul.dropdown-menu li {
  padding-left: 0px !important;
}

.nav-top {
  position: fixed;
  padding: 1em 30px;
  top: 0;
  width: 100%;
  background-image: -moz-linear-gradient(#2e3238 98px, rgba(0, 0, 0, 0) 98px);
  background-image: -o-linear-gradient(#2e3238 98px, rgba(0, 0, 0, 0) 98px);
  background-image: -webkit-linear-gradient(#2e3238 98px, rgba(0, 0, 0, 0) 98px);
  background-image: -ms-linear-gradient(top, #2e3238 98px, transparent 98px);
  background-image: linear-gradient(#2e3238 98px, rgba(0, 0, 0, 0) 98px);
  -webkit-transition: .0s all ease;
  -o-transition: .0s all ease;
  transition: .0s all ease;
  -webkit-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  transform: translateY(-100%);
}

.nav-top.awake {
  -webkit-transition: .3s all ease;
  -o-transition: .3s all ease;
  transition: .3s all ease;
  -webkit-transform: translateY(0%);
  -ms-transform: translateY(0%);
  transform: translateY(0%);
}

.nav-top.sleep {
  -webkit-transition: .3s all ease;
  -o-transition: .3s all ease;
  transition: .3s all ease;
  -webkit-transform: translateY(-80%);
  -ms-transform: translateY(-80%);
  transform: translateY(-80%);
}

.navbar-collapse {
  text-align: left;
}

.navbar-collapse.collapse.show {
  background: #111;
}

a.bg-primary:hover {
  background: #4869e8 !important;
}

@media (max-width: 480px) {
  .navbar-collapse {
    text-align: center;
    padding: 20px;
  }
}

@media (max-width: 400px) {
  .navbar-collapse {
    text-align: center;
    padding: 20px;
  }
}

@media (max-width: 768px) {
  .navbar-collapse {
    text-align: center;
    padding: 20px;
  }
}

.bg-1 {
  background: url("../images/bg/banner-1.jpg") no-repeat 50% 50%;
  background-size: cover;
}

.bg-2 {
  background: url("../images/bg/contact-bg-min-1.jpg") 50% 50%;
  background-size: cover;
}

.bg-4 {
  background: url("../images/bg/about-modern-img-1.jpg") 50% 50%;
  background-size: cover;
}

.slider {
  background: url("../images/bg/slider-1.jpg") no-repeat;
  background-size: cover;
  background-position: 10% 0%;
  padding: 220px 0 200px 0;
  position: relative;
}

.slider h1 {
  font-size: 56px;
  line-height: 70px;
}

.slider p {
  width: 65%;
  margin: 0 auto;
}

.slider span {
  letter-spacing: 8px;
  color: rgba(255, 255, 255, 0.85);
  font-size: 15px;
}

.letter-spacing {
  letter-spacing: 1px;
}

.mt--6 {
  margin-top: -4rem;
}

.slider-cta .btn:hover {
  border-color: #fff;
  background: transparent;
}

.slider .btn-main:hover, .slider .btn-transparent:hover, .slider .btn-small:hover {
  background: #fff;
  color: #4869e8;
  border-color: #fff;
}

@media (max-width: 480px) {
  .slider h1 {
    font-size: 27px;
    line-height: 38px;
  }
}

@media (max-width: 400px) {
  .slider h1 {
    font-size: 27px;
    line-height: 38px;
  }
}

@media (max-width: 768px) {
  .slider h1 {
    font-size: 37px;
    line-height: 48px;
  }
}

.intro-item i {
  font-size: 60px;
  line-height: 60px;
}

.color-one {
  color: #4869e8;
}

.color-two {
  color: #00d747;
}

.color-three {
  color: #9262ff;
}

.color-four {
  color: #088ed3;
}

.about-img {
  box-shadow: 0 30px 100px rgba(0, 0, 0, 0.17);
}

.about-img img {
  border-radius: 3px;
}

.feature span {
  color: rgba(0, 0, 0, 0.2);
}

.counter {
  padding: 130px 0px;
}

.counter-item .counter-stat {
  font-size: 50px;
}

.counter-item p {
  margin-bottom: 0px;
}

.bg-counter {
  background: url("../images/bg/banner-1.jpg") no-repeat;
  background-size: cover;
}

.team-item-wrap {
  overflow: hidden;
}

.team-item-wrap .team-item-content {
  text-align: center;
  padding: 15px 0px;
  transition: all 0.4s linear;
  -moz-transition: all 0.4s linear;
  -webkit-transition: all 0.4s linear;
  -o-transition: all 0.4s linear;
  margin-top: -60px;
  background: #fff;
  position: relative;
  margin-left: 15px;
  margin-right: 15px;
  border-bottom: 1px solid #f5f5f5;
  opacity: .97;
  border-radius: 4px 4px 0px 0px;
}

.team-social {
  margin-bottom: 0px;
}

.team-social li a {
  color: rgba(0, 0, 0, 0.5);
  transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  padding: 0px 6px;
  position: relative;
}

.team-social li a:hover {
  color: #4869e8;
}

.hover-content {
  text-align: center;
}

.service .card {
  border: 1px solid rgba(0, 0, 0, 0.05);
  position: relative;
  overflow: hidden;
}

.service .card i {
  transition: all .3s ease;
  -moz-transition: all .3s ease;
  -webkit-transition: all .3s ease;
  -o-transition: all .3s ease;
  -webkit-transform: translateY(0px);
  -moz-transform: translateY(0px);
  -ms-transform: translateY(0px);
  -o-transform: translateY(0px);
  transform: translateY(0px);
}

.service .card h4, .service .card i, .service .card p {
  position: relative;
}

.service .card h4 {
  transition: color 0.3s ease;
  -moz-transition: color 0.3s ease;
  -webkit-transition: color 0.3s ease;
  -o-transition: color 0.3s ease;
}

.service .card p {
  transition: color 0.3s ease;
  -moz-transition: color 0.3s ease;
  -webkit-transition: color 0.3s ease;
  -o-transition: color 0.3s ease;
}

.service .card:hover h4 {
  color: #fff;
}

.service .card:hover i {
  color: #fff;
  -webkit-transform: translateY(-10px);
  -moz-transform: translateY(-10px);
  -ms-transform: translateY(-10px);
  -o-transform: translateY(-10px);
  transform: translateY(-10px);
}

.service .card:hover p {
  color: rgba(255, 255, 255, 0.8);
}

.text-lg {
  font-size: 50px;
}

.service .card:before {
  position: absolute;
  content: "";
  left: 0px;
  bottom: -500px;
  width: 100%;
  height: 100%;
  background: #4869e8;
  transition: all .4s ease;
  -moz-transition: all .4s ease;
  -webkit-transition: all .4s ease;
  -o-transition: all .4s ease;
}

.service .card:hover:before {
  bottom: 0px;
}

.cta-block {
  margin-top: 80px;
}

.cta-block .btn:hover {
  background: #fff;
  color: #4869e8;
}

.videoplay {
  background: #4869e8;
  color: #fff;
  display: inline-block;
  text-align: center;
  width: 90px;
  height: 90px;
  font-size: 42px;
  padding-top: 25px;
  border-radius: 100%;
  position: absolute;
  left: 10px;
}

.videoplay:hover {
  color: #fff !important;
  cursor: pointer;
}

.videoplay:focus {
  color: #fff !important;
}

.cta-content-block {
  padding-left: 70px;
}

.font-primary {
  font-family: "Montserrat", sans-serif;
}

.font-secondary {
  font-family: "Source Sans Pro", sans-serif;
}

.cta h2 {
  text-transform: none;
}

.testimonial-item {
  padding: 50px 30px;
}

.testimonial-item p {
  font-size: 18px;
  line-height: 32px;
}

.slick-slide:focus, .slick-slide a {
  outline: none;
}

.shadow-md-2 {
  box-shadow: 0 18px 50px rgba(0, 0, 0, 0.07);
}

.hero-img {
  background: url("../images/bg/banner-1.jpg");
  position: absolute;
  content: "";
  background-size: cover;
  width: 100%;
  height: 100%;
  top: 0px;
}

.bg-absolute {
  position: absolute;
  content: "";
  background-size: cover;
  width: 100%;
  height: 100%;
  top: 0px;
}

.h70 {
  height: 55%;
}

.lh-45 {
  line-height: 38px;
}

.pricing-header h1 {
  font-size: 75px;
}

.pricing-header h1 small {
  font-size: 30%;
}

.pricing .btn-solid-border {
  border-color: #4869e8;
  color: #111;
}

.pricing .btn-solid-border:Hover {
  background: #4869e8;
  color: #fff;
}

.plan {
  background-color: #f1f3f7;
  border-radius: 50em;
  line-height: 1.5em;
  font-weight: 400;
  color: #2d3136;
  display: inline-block;
  padding: 4px 16px;
}

.pricing .card {
  -webkit-transition: all .35s ease;
  -moz-transition: all .35s ease;
  -ms-transition: all .35s ease;
  -o-transition: all .35s ease;
  transition: all .35s ease;
  -webkit-transform: translateY(0px);
  -moz-transform: translateY(0px);
  -ms-transform: translateY(0px);
  -o-transform: translateY(0px);
  transform: translateY(0px);
}

.pricing .card h1 {
  -webkit-transition: all .35s ease;
  -moz-transition: all .35s ease;
  -ms-transition: all .35s ease;
  -o-transition: all .35s ease;
  transition: all .35s ease;
}

.pricing .card .border-bottom {
  border-color: rgba(0, 0, 0, 0.07) !important;
}

.pricing .card:hover {
  -webkit-transform: translateY(10px);
  -moz-transform: translateY(10px);
  -ms-transform: translateY(10px);
  -o-transform: translateY(10px);
  transform: translateY(10px);
}

.pricing .card:hover h1 {
  color: #4869e8;
}

.shadow-sm-2 {
  box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.07);
}

.pricing-header.active span {
  background-color: #4869e8;
  color: #fff;
}

.portfolio .btn:focus, .portfolio .btn:hover {
  box-shadow: none !important;
}

.portfolio .btn.active {
  background: #4869e8;
  color: #fff;
}

.portfolio .btn {
  border-radius: 4px !important;
}

.portfolio .btn-group:not(:first-child) > .btn, .portfolio .btn-group > .btn:not(:first-child) {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.inner-box {
  position: relative;
}

.inner-box .image {
  position: relative;
}

.inner-box .image img {
  position: relative;
  width: 100%;
  display: block;
}

.inner-box .image .overlay-box {
  position: absolute;
  left: 0px;
  bottom: 0px;
  right: 0px;
  top: 0px;
  z-index: 2;
  opacity: 0;
  display: block;
  -webkit-transition: all 900ms ease;
  -ms-transition: all 900ms ease;
  -o-transition: all 900ms ease;
  transition: all 900ms ease;
}

.inner-box .image .overlay-box:before {
  position: absolute;
  content: '';
  right: 0px;
  top: 0px;
  width: 50%;
  height: 100%;
  display: block;
  opacity: 0;
  -webkit-transform: perspective(400px) rotateY(-90deg);
  -moz-transform: perspective(400px) rotateY(-90deg);
  -ms-transform: perspective(400px) rotateY(-90deg);
  -o-transform: perspective(400px) rotateY(-90deg);
  transform: perspective(400px) rotateY(-90deg);
  -webkit-transform-origin: right;
  -moz-transform-origin: right;
  -ms-transform-origin: right;
  -o-transform-origin: right;
  transform-origin: right;
  -ms-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  background-color: #4869e8;
}

.inner-box:hover .image .overlay-box:before {
  opacity: 1;
  -webkit-transform: perspective(400px) rotateY(0deg);
  -moz-transform: perspective(400px) rotateY(0deg);
  -ms-transform: perspective(400px) rotateY(0deg);
  -o-transform: perspective(400px) rotateY(0deg);
  transform: perspective(400px) rotateY(0deg);
}

.inner-box .image .overlay-box:after {
  position: absolute;
  content: '';
  left: 0px;
  top: 0px;
  width: 50%;
  height: 100%;
  display: block;
  opacity: 0;
  -webkit-transform: perspective(400px) rotateY(90deg);
  -moz-transform: perspective(400px) rotateY(90deg);
  -ms-transform: perspective(400px) rotateY(90deg);
  -o-transform: perspective(400px) rotateY(90deg);
  transform: perspective(400px) rotateY(90deg);
  -webkit-transform-origin: left;
  -moz-transform-origin: left;
  -ms-transform-origin: right;
  -o-transform-origin: left;
  transform-origin: left;
  -ms-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  background-color: #4869e8;
}

.inner-box:hover .image .overlay-box:after {
  opacity: 1;
  -webkit-transform: perspective(400px) rotateY(0deg);
  -moz-transform: perspective(400px) rotateY(0deg);
  -ms-transform: perspective(400px) rotateY(0deg);
  -o-transform: perspective(400px) rotateY(0deg);
  transform: perspective(400px) rotateY(0deg);
}

.inner-box:hover .image .overlay-box {
  opacity: 1;
}

.inner-box .image .overlay-box .overlay-inner {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  display: table;
  text-align: left;
  z-index: 1;
  padding: 10px 40px;
  vertical-align: middle;
}

.inner-box .image .overlay-box .overlay-inner .overlay-content {
  position: relative;
  display: table-cell;
  vertical-align: middle;
}

.inner-box .image h3 {
  color: #fff;
}

.contact-form-wrap .form-group {
  padding-bottom: 15px;
  margin: 0px;
}

.contact-form-wrap .form-group .form-control {
  background: #f5f5f5;
  height: 48px;
  border: 1px solid #EEF2F6;
  box-shadow: none;
  width: 100%;
}

.contact-form-wrap .form-group-2 {
  margin-bottom: 13px;
}

.contact-form-wrap .form-group-2 textarea {
  background: #f5f5f5;
  height: 135px;
  border: 1px solid #EEF2F6;
  box-shadow: none;
  width: 100%;
}

.address-block li {
  margin-bottom: 10px;
}

.address-block li i {
  font-size: 20px;
  width: 20px;
}

.social-icons li {
  margin: 0 6px;
}

.social-icons i {
  margin-right: 15px;
  font-size: 25px;
}

.google-map {
  position: relative;
}

.google-map #map {
  width: 100%;
  height: 450px;
}

/*=================================================================
  Latest Posts
==================================================================*/
.blog-item {
  -webkit-transition: all .35s ease;
  -moz-transition: all .35s ease;
  -ms-transition: all .35s ease;
  -o-transition: all .35s ease;
  transition: all .35s ease;
}

.blog-item h3 {
  font-size: 22px;
}

.blog-item h2 {
  font-size: 34px;
  line-height: 43px;
}

.sidebar-widget .list-group-item {
  background-color: transparent;
  border: 0px;
}

.sidebar-widget .list-group-item:first-child {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.sidebar-widget h5 {
  background: #dedede;
  padding: 4px;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.tag-option a {
  padding: 5px 15px;
  border: 1px solid #dedede;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #2b2e3a;
}

.tags a {
  background: #2b2e3a;
  padding: 3px 15px;
  display: inline-block;
  margin-bottom: 5px;
  text-transform: uppercase;
  color: #fff;
}

.comment-form .form-control {
  border-color: #eee;
}

.post-navigation a {
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  border: 1px solid #dedede;
  text-align: center;
  padding-top: 4px;
  margin-right: 6px;
  font-weight: 700;
}

.post-navigation a.current {
  background: #4869e8;
  color: #fff;
  border-color: #4869e8;
}

h3.quote {
  font-size: 25px;
  line-height: 40px;
  font-weight: normal;
  padding: 0px 25px 0px 85px;
  margin: 65px 0 65px 0 !important;
  position: relative;
}

h3.quote::before {
  content: '';
  width: 55px;
  height: 2px;
  background: #4869e8;
  position: absolute;
  top: 25px;
  left: 0;
}

.mt-70 {
  margin-top: -70px;
}

.border-1 {
  border: 1px solid rgba(0, 0, 0, 0.05);
}

.share a {
  display: inline-block;
  padding: 4px 12px;
  color: #fff;
}

a.fb {
  background: #3b5999;
}

a.twt {
  background: #55acee;
}

a.pint {
  background: #bd081c;
}

a.link {
  background: #0077B5;
}

/*=================================================================
  Single Blog Page
==================================================================*/
.post.post-single {
  border: none;
}

.post.post-single .post-thumb {
  margin-top: 30px;
}

.post-sub-heading {
  border-bottom: 1px solid #dedede;
  padding-bottom: 20px;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-size: 16px;
  margin-bottom: 20px;
}

.post-social-share {
  margin-bottom: 50px;
}

.post-comments {
  margin: 30px 0;
}

.post-comments .media {
  margin-top: 20px;
}

.post-comments .media > .pull-left {
  padding-right: 20px;
}

.post-comments .comment-author {
  margin-top: 0;
  margin-bottom: 0px;
  font-weight: 500;
}

.post-comments .comment-author a {
  color: #4869e8;
  font-size: 14px;
  text-transform: uppercase;
}

.post-comments time {
  margin: 0 0 5px;
  display: inline-block;
  color: #808080;
  font-size: 12px;
}

.post-comments .comment-button {
  color: #4869e8;
  display: inline-block;
  margin-left: 5px;
  font-size: 12px;
}

.post-comments .comment-button i {
  margin-right: 5px;
  display: inline-block;
}

.post-comments .comment-button:hover {
  color: #4869e8;
}

.post-excerpt {
  margin-bottom: 60px;
}

.post-excerpt h3 a {
  color: #000;
}

.post-excerpt p {
  margin: 0 0 30px;
}

.post-excerpt blockquote.quote-post {
  margin: 20px 0;
}

.post-excerpt blockquote.quote-post p {
  line-height: 30px;
  font-size: 20px;
  color: #4869e8;
}

.single-blog {
  background-color: #fff;
  margin-bottom: 50px;
  padding: 20px;
}

.blog-subtitle {
  font-size: 15px;
  padding-bottom: 10px;
  border-bottom: 1px solid #dedede;
  margin-bottom: 25px;
  text-transform: uppercase;
}

.next-prev {
  border-bottom: 1px solid #dedede;
  border-top: 1px solid #dedede;
  margin: 20px 0;
  padding: 25px 0;
}

.next-prev a {
  color: #000;
}

.next-prev a:hover {
  color: #4869e8;
}

.next-prev .prev-post i {
  margin-right: 10px;
}

.next-prev .next-post i {
  margin-left: 10px;
}

.social-profile ul li {
  margin: 0 10px 0 0;
  display: inline-block;
}

.social-profile ul li a {
  color: #4e595f;
  display: block;
  font-size: 16px;
}

.social-profile ul li a i:hover {
  color: #4869e8;
}

.comments-section {
  margin-top: 35px;
}

.author-about {
  margin-top: 40px;
}

.post-author {
  margin-right: 20px;
}

.post-author > img {
  border: 1px solid #dedede;
  max-width: 120px;
  padding: 5px;
  width: 100%;
}

.comment-list ul {
  margin-top: 20px;
}

.comment-list ul li {
  margin-bottom: 20px;
}

.comment-wrap {
  border: 1px solid #dedede;
  border-radius: 1px;
  margin-left: 20px;
  padding: 10px;
  position: relative;
}

.comment-wrap .author-avatar {
  margin-right: 10px;
}

.comment-wrap .media .media-heading {
  font-size: 14px;
  margin-bottom: 8px;
}

.comment-wrap .media .media-heading a {
  color: #4869e8;
  font-size: 13px;
}

.comment-wrap .media .comment-meta {
  font-size: 12px;
  color: #888;
}

.comment-wrap .media p {
  margin-top: 15px;
}

.comment-reply-form {
  margin-top: 80px;
}

.comment-reply-form input, .comment-reply-form textarea {
  height: 35px;
  border-radius: 0;
  box-shadow: none;
}

.comment-reply-form input:focus, .comment-reply-form textarea:focus {
  box-shadow: none;
  border: 1px solid #4869e8;
}

.comment-reply-form textarea, .comment-reply-form .btn-main, .comment-reply-form .btn-transparent, .comment-reply-form .btn-small {
  height: auto;
}

.widget {
  margin-bottom: 30px;
  padding-bottom: 35px;
}

.widget .widget-title {
  margin-bottom: 15px;
  padding-bottom: 10px;
  font-size: 16px;
  color: #333;
  font-weight: 500;
  border-bottom: 1px solid #dedede;
}

.widget.widget-latest-post .media .media-object {
  width: 100px;
  height: auto;
}

.widget.widget-latest-post .media .media-heading a {
  color: #111;
  font-size: 16px;
}

.widget.widget-latest-post .media p {
  font-size: 12px;
  color: #808080;
}

.widget.widget-category ul li {
  margin-bottom: 10px;
}

.widget.widget-category ul li a {
  color: #837f7e;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.widget.widget-category ul li a:before {
  padding-right: 10px;
}

.widget.widget-category ul li a:hover {
  color: #4869e8;
  padding-left: 5px;
}

.widget.widget-tag ul li {
  margin-bottom: 10px;
  display: inline-block;
  margin-right: 5px;
}

.widget.widget-tag ul li a {
  color: #837f7e;
  display: inline-block;
  padding: 8px 15px;
  border: 1px solid #dedede;
  border-radius: 30px;
  font-size: 14px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.widget.widget-tag ul li a:hover {
  color: #fff;
  background: #4869e8;
  border: 1px solid #4869e8;
}

.footer {
  background: #24282f;
  padding-bottom: 10px;
}

.footer .copyright a {
  font-weight: 600;
}

.lh-35 {
  line-height: 35px;
}

.logo {
  letter-spacing: 1px;
}

.footer-menu a {
  color: rgba(255, 255, 255, 0.8);
}

.footer-btm {
  border-top: 1px solid rgba(255, 255, 255, 0.03);
}

.footer-socials li a {
  margin: 0px 0px 6px 0px;
  display: block;
  text-transform: capitalize;
  letter-spacing: 1px;
  color: rgba(255, 255, 255, 0.8);
}

.footer .widget p {
  color: rgba(255, 255, 255, 0.8);
}

/*=== MEDIA QUERY ===*/
@media (max-width: 400px) {
  .header-top .header-top-info a {
    margin-left: 0px;
    display: block;
  }
  .navbar-toggler {
    color: #fff;
  }
  .slider .block h1 {
    font-size: 28px;
    line-height: 40px;
  }
  .slider {
    padding: 126px 0 151px 0;
  }
  .content-title {
    font-size: 28px;
    line-height: 46px;
  }
  .bg-about {
    display: none;
  }
  .p-5 {
    padding: 2rem !important;
  }
  h2, .h2 {
    font-size: 1.3rem;
    font-weight: 600;
    line-height: 36px;
  }
  .testimonial-item .testimonial-item-content {
    padding-left: 0px;
    padding-top: 30px;
  }
  .footer-socials {
    margin-top: 20px;
  }
  .footer-socials li a {
    margin-left: 0px;
  }
  .text-lg {
    font-size: 3rem;
  }
  .header-top .header-top-info {
    padding-bottom: 15px;
  }
  .blog-item-meta span {
    margin: 6px 0px;
  }
  .widget {
    margin-bottom: 30px;
    padding-bottom: 0px;
  }
  .dropdown-menu {
    display: none;
    width: 100%;
    text-align: center;
  }
}

@media (max-width: 480px) {
  .header-top .header-top-info a {
    margin-left: 0px;
  }
  .navbar-toggler {
    color: #fff;
  }
  .slider .block h1 {
    font-size: 38px;
    line-height: 50px;
  }
  .slider {
    padding: 126px 0 151px 0;
  }
  .content-title {
    font-size: 28px;
    line-height: 46px;
  }
  .bg-about {
    display: none;
  }
  .p-5 {
    padding: 2rem !important;
  }
  h2, .h2 {
    font-size: 1.3rem;
    font-weight: 600;
    line-height: 36px;
  }
  .testimonial-item .testimonial-item-content {
    padding-left: 0px;
    padding-top: 30px;
  }
  .footer-socials {
    margin-top: 20px;
  }
  .footer-socials li a {
    margin-left: 0px;
  }
  .blog-item-meta span {
    display: block;
    margin: 6px 0px;
  }
  .widget {
    margin-bottom: 30px;
    padding-bottom: 0px;
  }
  .dropdown-menu {
    display: none;
    width: 100%;
    text-align: center;
  }
}

@media (max-width: 768px) {
  .navbar-toggler {
    color: #fff;
  }
  .bg-about {
    display: none;
  }
  .footer-socials {
    margin-top: 20px;
  }
  .footer-socials li a {
    margin-left: 0px;
  }
  .slider .block h1 {
    font-size: 56px;
    line-height: 70px;
  }
  .blog-item-meta span {
    display: block;
    margin: 6px 0px;
  }
  .widget {
    margin-bottom: 30px;
    padding-bottom: 0px;
  }
  .dropdown-menu {
    display: none;
    width: 100%;
    text-align: center;
  }
}
