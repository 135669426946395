// Intro Section


.intro-item{

	i{
		font-size:60px;
		line-height: 60px;
	}
	
}

.color-one{
	color: $primary-color;
}
.color-two{
	color: #00d747;
}
.color-three{
	color:#9262ff;
}
.color-four{
	color:#088ed3;
}