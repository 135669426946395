

.team-item-wrap{
	overflow: hidden;
	.team-item-content{
		text-align: center;
		padding: 15px 0px;
		transition: all 0.4s linear;
		-moz-transition: all 0.4s linear;
		-webkit-transition: all 0.4s linear;
		-o-transition: all 0.4s linear;
		margin-top: -60px;
		background: $light;
		position: relative;
		margin-left: 15px;
		margin-right: 15px;
		border-bottom: 1px solid #f5f5f5;
		opacity: .97;
		border-radius: 4px 4px 0px 0px;
	}
}
 .team-social{
 	margin-bottom: 0px;
 }
.team-social li a{
	color: rgba(0,0,0,0.5);
    transition: all 0.3s linear;
	-moz-transition: all 0.3s linear;
	-webkit-transition: all 0.3s linear;
	-o-transition: all 0.3s linear;
	padding: 0px 6px;
	position: relative;
	

	&:hover{
		color: $primary-color;
	}
 }



.hover-content{
	text-align: center;
}

