.bg-1 {
	background: url("../images/bg/banner-1.jpg") no-repeat 50% 50%;
	background-size: cover;
}

.bg-2 {
	background: url("../images/bg/contact-bg-min-1.jpg") 50% 50%;
	background-size: cover;
}

.bg-4 {
	background: url("../images/bg/about-modern-img-1.jpg") 50% 50%;
	background-size: cover;
}
